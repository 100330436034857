<template>
    <v-container>
        <v-card>
            <v-card-title><v-icon>mdi-help</v-icon>&nbsp;Um kerfið</v-card-title>
            <v-card-text>Pottastýringarkerfi Trefja hf.</v-card-text>
            <v-card-text>
            Kerfið er í svokölluðum BETA-prófunum. Það þýðir að valdir aðilar fá pottstýringar til prófunar og
            endurgjafar upplýsinga um reynslu af notkun þeirra í því skyni að finna hnökra á virkni, útliti og upplifun
            svo leiðrétta megi það sem betur má fara fyrir endanlega fyrstu útgáfu.
            </v-card-text>
            <v-card-text>
            Bilun: Í augnablikinu er ekki mögulegt að endurheimta aðgang ef aðgangsorð týnist - unnið er að viðgerð.
            </v-card-text>
            <v-card-text>
            Gildandi útgáfur:<br>
              Útgáfa þjóns: 0.9.0 (beta)<br>
              Útgáfa stýringa: 0.3.3-28 (beta)
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
export default {
    
}
</script>
