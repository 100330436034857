<template>
<v-container fluid class="my-4 mx-2 pa-0">

    <v-row>
        <v-col cols="10" class="px-5">
            <span>
                <v-icon>mdi-cog-outline</v-icon>
            </span> <span class="header-1">Stillingar</span>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="10">
            <v-card-subtitle>Nafn og lýsing</v-card-subtitle>
        </v-col>
    </v-row>

    <v-card class="mx-4">
        <v-row>
            <div></div>
            <v-col cols="8">
                <v-text-field class="px-4" :placeholder="currentDevice" v-model="newName" :rules="titleRules" label="Nafn" />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="8">
                <v-text-field class="px-4" :placeholder="description" v-model="description" label="Lýsing" />
            </v-col>
        </v-row>
    </v-card>
        <div  class="ma-4">
        <v-row>
            <v-col cols="4">
                <v-card-actions>
                    <v-btn @click="save()" outlined block color="primary"> Vista </v-btn>
                </v-card-actions>
            </v-col>
            <v-col cols="6" class="px-5">

            </v-col>
        </v-row>
    </div>
    <v-row>
        <v-col cols="10">
            <v-card-subtitle>{{props.tooltip}}</v-card-subtitle>
        </v-col>
    </v-row>
    <v-card class="mx-4">

        <v-row>
            <v-col cols="12">
                <v-card-subtitle>{{props.description}}</v-card-subtitle>

                <v-card-actions>
                    <v-btn @click="remove()" outlined class="block" color="secondary">
                        Aftengja tæki
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
    </v-card>


</v-container>
</template>

<script>
import {
    mapGetters,
    mapActions
    } from "vuex";
//import GeneralDialog from "@/components/GeneralDialog";
import EventBus from '@/plugins/eventBus'

export default {
    data() {
        return {
            newName: "",
            newImage: null,
            description: "",
            currentDevice: "",
            id: -1,
            tempRules: [
              v => !isNaN(v)  || 'Hitastigið verður að vera tala',
              //v => Number(v) == 0 || 'Hitastigið verður að vera tala',
              v => v < 10  || 'Má ekki vera hærra en 10',
              v => v > -10  || 'Má ekki vera lægra en -10',
                

            ],
            titleRules: [
              v => v.length < 26 || 'Titill má ekki vera lengri en 26 stafir',
            ],
            temperature_calibration:[{value:"0"},{value:"0"}],
            offsetTempValue:{
              first:"0.0",
              second:"0.0"
            },
            props: {
                color: "secondary",
                action: "removeDevice",
                buttonTitle: "Eyða",
                attribute: "block",
                title: "Eyða tæki",
                icon: "mdi-delete",
                isIcon: true,
                text: "Ertu viss um að þú viljir eyða tæki úr lista",
                value: Number(this.$route.params.id),
                tooltip: "Afpara aðgang og stýringu",
                description: "Hér má afpara stýringuna og aðganginn. Þetta er aðeins notað til prófana við Beta-útgáfu og verður fjalægt."
            },
        };
    },

    computed: {
        ...mapGetters(["devices"]),
        Module(){
          return this.$store.state.Device.modules.find(m=>m.deviceId == this.$route.params.id)
        }
        
    },
    async mounted() {

      if(this.devices == null || this.devices.length == 0){
        await this.getDevices()
      }
        
      EventBus.$on("device-deleted", () => {
        this.$router.push({
            path: "/userboard",
        });
      })
      this.id = Number(this.$route.params.id);

      let cDevice = this.devices.find((m) => m.id == this.id);
      if (cDevice && cDevice.name) {
          this.currentDevice = cDevice.name;
      } else {
          this.currentDevice = "t.d. Heitur pottur heima";
      }

      this.temperature_calibration = await this.$store.dispatch("GET_TEMPERATURE_CALIBRATION",this.id);
      console.log("this.temperature_calibration",this.temperature_calibration)
        EventBus.$on("set_temperature_calibration", (resp)=> {
        console.log("temperature set",resp)
      })
    },
    methods: {
      ...mapActions(["getDevices"]),
      increase_temp(type){
        if(type==1){
          let first = Number(this.temperature_calibration[0].value)
          first += 0.1;
          //this.offsetTempValue.first = "0.0"
          
          this.temperature_calibration[0].value = first.toLocaleString(
            undefined, // leave undefined to use the browser's locale,
                      // or use a string like 'en-US' to override it.
            { minimumFractionDigits: 1 }
          );



        }else{
          let second = Number(this.temperature_calibration[1].value)
          second += 0.1;
          this.temperature_calibration[1].value = second.toLocaleString(
            undefined, // leave undefined to use the browser's locale,
                      // or use a string like 'en-US' to override it.
            { minimumFractionDigits: 1 }
          );

        }
      },
      decrease_temp(type){
         if(type==1){
          let first = Number(this.temperature_calibration[0].value)
          first -= 0.1;
          this.temperature_calibration[0].value = first.toLocaleString(
            undefined, // leave undefined to use the browser's locale,
                      // or use a string like 'en-US' to override it.
            { minimumFractionDigits: 1 }
          );

        }else{
          let second = Number(this.temperature_calibration[1].value)
          second -= 0.1;
          this.temperature_calibration[1].value = second.toLocaleString(
            undefined, // leave undefined to use the browser's locale,
                      // or use a string like 'en-US' to override it.
            { minimumFractionDigits: 1 }
          );

        }

      },
      async save() {
          await this.$store.dispatch("SET_DEVICE_PROPERTIES", {
              id: this.id,
              name: this.newName,
              description: this.description,
          });

          this.$router.push({
              path: "/userboard",
          });
      },

      async remove() {
          this.dialog = false;
          await this.$store.dispatch(this.props.action, this.props.value);
      },
      async saveCalibration() {
         const data = {
          command: "set_temperature_calibration",
          module_id: this.Module.moduleId,
          args: JSON.stringify([this.temperature_calibration[0].value,this.temperature_calibration[1].value]),
        };
        this.$store.dispatch("sendCommand", {
          id: this.Module.id,
          data: data,
      });
      }
    },
};
</script>

<style scoped>
.subheader {
    font-size: 1em;

}

.header-1 {
    font-size: 1em;
    font-weight: 500;
}

.button-left{
  top:60px;
}

.button-right {
  left:30px;
  top:60px;
}
</style>
