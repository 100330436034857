<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant="miniVariant"
      :clipped="clipped"
      fixed
      app
    >
      <v-list>
        <v-list-item
          v-for="(item, i) in items.filter(x => x.show)"
          :key="i"
          :to="item.to"
          @click="handle(item)"
          router
          exact
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn class="logout" @click="logout()" block>
            Útskráning
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-app-bar v-if="appBarVisible" color="#156581" dense dark>
        <v-icon v-if="backArrowVisible" @click="back()">mdi-arrow-left</v-icon>
        <v-app-bar-nav-icon v-else  @click.stop="drawer = !drawer" />
        <v-spacer />
        <img src="./../assets/trefjar.svg" contain width="64px">
      </v-app-bar>
    <router-view />
    <alert class="alert-pos" v-if="isAlert"></alert>

    </v-main>
  </v-app>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import tokenManager from '@/services/tokenManager'
import Alert from '@/components/Alerts.vue'
export default {
  data () {
    return {
      clipped: false,
      drawer: false,
      fixed: false,
      isAlert:true,
      items: [
        {
          icon: 'mdi-information-variant',
          title: 'Upplýsingar',
          to: '/info',
          show: this.modules !== null
        },
        {
          icon: 'mdi-hot-tub',
          title: 'Heitur Pottur',
          to: '/',
          show: this.modules !== null
        },
        {
          icon: 'mdi-thermometer-plus',
          title: 'Bæta við potti',
          to: '/new-device',
          show: this.module !== null
        },
        {
          icon: 'mdi-help',
          title: 'Um kerfið',
          to: '/about',
          show: this.module !== null
        }
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'Vuetify.js'
    }
  },
  components:{
    Alert
  },
  computed: {
    ...mapGetters(['modules', 'module']),

    // currentUser () {
    //   return this.$auth.user
    // },
    backArrowVisible(){
      if (this.$route.name === 'register') { return true }
      if (this.$route.name === 'forgotPassword') { return true }
      if (this.$route.name === 'editdevice') { return true }
      //if (this.$route.name === 'register') { return false }

      return false
    },
    isAuth(){

      return tokenManager.isAuth();
    },
    appBarVisible () {
      if (this.$route.name === 'login') { return false }
      //if (this.$route.name === 'register') { return false }

      return true
    }
  },


  methods: {
    ...mapActions(['setModule']),

    handle (item) {
      this.drawer = false
      if (item.title === 'Home') {
        this.setModule(null)
      }
    },
    

    async logout () {
      await this.$store.dispatch("logout").catch(() => {

    })
    
      this.drawer = false
      this.$router.push({path:'/'})
    },
    back(){
      this.$router.back()
    }
  }

}
</script>


<style scoped>
.logout {
  background:rgb(21, 101, 129) !important;
  color:white !important;
}

.alert-pos{
  position:absolute;
  z-index:3;
  bottom:5px;
  width:100%

}
</style>
