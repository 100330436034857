<template>
  <v-container v-if="isMounted" fill-height>
    <v-row>
      <v-col
        :cols="$bp.cols($vuetify.breakpoint.name)"
        :offset="$bp.offset($vuetify.breakpoint.name)"
      >
        <img @click="userLogout()" src="@/assets/trefjar.svg" contain class="img" width="256">
        <Login />
      </v-col>
    </v-row>
    <v-row >
      <v-col cols="6" class="px-5" >
          <v-btn class="accent" @click="forgot">
            Týnt aðgangsorð
          </v-btn>
      </v-col>
      <v-col cols="6"  class="px-5">
          <v-btn class="secondary" @click="register" style="float:right">
            Nýr notandi
          </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Login from './../components/login.vue'
export default {
  components: {
    Login
  },

  data () {
    return {
      isMounted: false
    }
  },

  mounted () {
    this.isMounted = true
  },
  
  methods:{
    forgot(){
      this.$router.push({
        path:'/forgot'
      })
    },
    register(){
      this.$router.push({
        path:'/register'
      })

    }
  }
}
</script>
<style lang="scss" scoped>

.img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

</style>
