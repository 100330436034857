import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
            primary: '#156581',
            secondary: '#ACBDBA',
            accent: '#453643',
            error: '#C2847A',
            },
        },
    }
});
