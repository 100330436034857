  
import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/views/login.vue'
import RegisterView from '@/views/register.vue'
import UserBoardView from '@/views/userboard.vue'
import tokenManager from '@/services/tokenManager'
import AboutView from '@/views/about'
import NewDevice from '@/views/newDevice'
import ForgotPassword from '@/views/forgotPW'
import EditDevice from '@/views/editDevice'
import Information from '@/views/information.vue'
 
Vue.use(Router)

let router = new Router({
  mode:'history',
  routes: [
    { 
      path: '/', 
      name: 'login', 
      component: LoginPage, 
      meta:{
        guest: true
      } 
  },
  { 
    path: '/register', 
    name: 'register', 
    component: RegisterView,
    meta:{
      guest:true
    }
  },
  { 
    path: '/forgot', 
    name: 'forgotPassword', 
    component: ForgotPassword,
    meta:{
      guest:true
    } 
  },
  { 
    path: '/userboard', 
    name: 'userboard', 
    component: UserBoardView,
    meta:{
      requiresAuth: true
    }
  },
  { 
    path: '/about', 
    name: 'about', 
    component: AboutView,
    meta:{
      requiresAuth: true
    },
  },
  { 
    path: '/new-device', 
    name: 'newdevice', 
    component: NewDevice,
    meta:{
      requiresAuth: true
    },
  },
  { 
    path: '/info', 
    name: 'info', 
    component: Information,
    meta:{
      requiresAuth: true
    },
  },
  { 
    path: '/editdevice/:id', 
    name: 'editdevice', 
    component: EditDevice,
    meta:{
      requiresAuth: true
    }
  },
 
  { path: '*', redirect: { name: 'login' } }
  ]
})

router.beforeEach((to, from, next) => {

  if(to.matched.some(record => record.meta.requiresAuth)) {
      if (!tokenManager.isAuth()) {
          next({
              path: '/login',
              params: { nextUrl: to.fullPath }
          })
      } else {
          
          next()
    }
  }
  else if(to.matched.some(record => record.meta.guest)) {
    if(!tokenManager.isAuth()){
        next()
    }
    else{
        next({ name: 'userboard'})
    }
  }
  else {
      next()
  }
})

export default router;