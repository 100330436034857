<template>
  <v-container v-if="isMounted" fill-height>
    <v-row>
      <v-col
        :cols="$bp.cols($vuetify.breakpoint.name)"
        :offset="$bp.offset($vuetify.breakpoint.name)"
      >
        <img src="@/assets/trefjar.svg" contain class="img" width="256">
        <Register />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Register from '@/components/register.vue'

export default {
  components: {
    Register
  },

  data () {
    return {
      isMounted: false
    }
  },
  methods:{
      userLogout(){
          this.$store.dispatch('logout')
      }
  },

  computed: {
    cols () {
      return this.$vuetify.breakpoint.mdAndDown ? 12 : 6
    },

    offset () {
      return this.$vuetify.breakpoint.mdAndDown ? 0 : 3
    }
  },

  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss" scoped>

.img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

</style>
