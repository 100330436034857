import HttpRequest from './http_request'
import Cookie from 'js-cookie'
import tokenManager from '@/services/tokenManager.js'
class AuthProvider extends HttpRequest {
  async loginUser (data) {
    try{
      const response = await this.create('auth/authorizeUser', data)
      const tokenData = response.data;
      
      const access_token = {"token":tokenData.accessToken.token , "expires":tokenData.accessToken.expiredUTCMilliseconds}
      const refresh_token = {"token":tokenData.refreshToken.token , "expires":tokenData.refreshToken.expiredUTCMilliseconds}
  
      tokenManager.setToken(JSON.stringify(access_token), "access_token");
      tokenManager.setToken(JSON.stringify(refresh_token), "refresh_token");
      Cookie.set(tokenData.userModel, "user");
  
      return this.responseHandler(response);

    }catch(error){
      const result = this.responseHandler(error.response);
      return result;
    }

  }

  async registerUser (data) {
    try{
      const response = await this.create('/users/register', data)
      return this.responseHandler(response);
    }
    catch(error){
      const result = this.responseHandler(error.response);
      return result;
    }
  }

  async logout(){
    const token = tokenManager.refreshToken();
    return await this.create('/auth/revoke-token', {token})

  }

  async forgotPW(payload){
    try{
      const response = await this.create('users/passwordrequest',payload)
      return this.responseHandler(response);
    }
    catch(error){
      const result = this.responseHandler(error.response);
      return result;
    }
  }

}

export default AuthProvider
