<template>
  <v-container pa-0 ma-0>
    <v-card flat>
      <v-card-subtitle v-if="loginError" style="color: #E4497D; padding-bottom: 0px">
        Rangt póstfang eða aðgamgsorð <!-- Incorrect username or password -->
      </v-card-subtitle>
      <v-col>
        <v-text-field v-model="loginEmail" :rules="emailRules" label="Póstfang" prepend-inner-icon="mdi-email-outline" />
        <v-text-field v-model="loginPassword" :rules="passwordRules" label="Aðgangsorð" autocomplete="new-password" type="Password" prepend-inner-icon="mdi-lock-outline" />
        <v-btn @click="login" block class="primary" @keypress.enter="login">
          Innskráning
        </v-btn>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data () {
    return {
      loginError: false,
      loginEmail: '',
      loginPassword: '',
       emailRules: [
        v => !!v || 'Póstfangs krafist',//'An e-mail is required',
        v => /.+@.+\..+/.test(v) || 'Póstfang þarf að vera gilt póstfang (email)', //'E-mail must be valid',
        v => v !== this.emailTaken || 'Þetta póstfang er þegar í notkun', // 'E-mail is already taken'
      ],
      passwordRules: [
          v => v.length > 7 || 'Aðgangsorð þarf að vera minnst 8 stafir' // 'Mininum password length is 8 characters'
      ],
    }
  },
  mounted(){
    window.addEventListener('keydown', (event) => {
      if (event.keyCode === 13) { 
        this.login(event);
      }
    });
  },

  methods: {
    async login (e) {
      e.preventDefault()
     
      const email = this.loginEmail;
      const password = this.loginPassword;
      
      this.$store.dispatch('login', {email,password}).then( () =>{
        this.$router.push('info')

      }).catch((e) =>{
        this.error = e.message
      })
         
        //await AuthProvider.authenticate({email,password})

        //this.loginError = !await this.$userLogin(this.loginEmail, this.loginPassword)
      
    }
  }
}
</script>
