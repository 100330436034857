import axios from 'axios'
import appConfig from '@/appconfig.json'
import tokenManager from '@/services/tokenManager'
let axiosInstance = axios.create({
  baseURL: appConfig.server_url,///'http://192.168.20.84:5000',
  timeout: 120000
})

axiosInstance.interceptors.request.use(function (config) {
  const token = tokenManager.accessToken()//Cookie.get("access_token");
  config.headers["Authorization"] = `Bearer ${token}`;
  // Do something before request is sent
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
  // Do something with response data
  
  return response
}, function (error) {
  // Do something with response error
  return Promise.reject(error)
})

class HttpRequest {
  #errorTrials=0;
  constructor () {
    this.axios = axios
  }

  setHeader (header) {
    axiosInstance.defaults.headers.common[header.key] = header.value
    axiosInstance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

  }

  async fetch (methodName, data) {
      const result = await axiosInstance.get(methodName, {
        params: data
      }).catch( async error =>{
        this.#errorTrials +=1
        if(this.#errorTrials < 2){
          if(!error.response){
            return error;
          }
          await this.responseHandler(error.response,this.fetch(methodName,data));
        }else{
          this.#errorTrials =0
        }
        
      })
    return result;
  }

  create (methodName, data) {
    return axiosInstance.post(methodName, data).catch( async error =>{
      this.#errorTrials += 1
      if(this.#errorTrials < 2){

        if(error.response && error.response.status == 401 && error.response.statusText == "Unauthorized"){
          await this.responseHandler(error.response, this.create(methodName,data));
        }
        else{
          this.#errorTrials = 0;
          throw error.response;
        }

      }else{
        this.#errorTrials =0
        return error
      }
      
    })
  }

  update (methodName, data) {
    return axiosInstance.put(methodName, data).catch( async error =>{
      this.#errorTrials +=1
      if(this.#errorTrials < 2){
        if(!error.response){
          return error;
        }
        await this.responseHandler(error.response,this.update(methodName,data));
      }else{
        this.#errorTrials =0
      }
      
    })
  }

  delete (methodName, id) {
    return axiosInstance.delete(methodName, { params: {id: id} }).catch( async error =>{
        this.#errorTrials +=1
        if(this.#errorTrials < 2){
          if(!error.response){
            return error;
          }
          if(error.response.status == 400)
          {
            this.#errorTrials = 0;
            throw error;
          }
          await this.responseHandler(error.response,this.delete(methodName,id));
        }else{
          this.#errorTrials =0
        }
        
      })
  }

  async responseHandler(response,callback){
    if(response && response.status == 401 && response.statusText == "Unauthorized"){
      try{
        //return {status: response.status, alert: {type:'error', message:response.statusText}, error:true};
        //let result = await this.create('/auth/refreshtoken',{token:tokenManager.refreshToken()})
        //tokenManager.storeToken(result)
         if(callback){
           callback()
         }
      }
      catch(error){
        return {status: response.status, alert: {type:'error', message:response.data.error}, error:true}; 
      }
      return;
    }
    if(response && response.status != 200){
      console.log("response not 200")
      if(response.data && response.data.title == 'ModelState'){
        let errors =""
        Object.values(response.data.errors).forEach(el => {
          errors += el +"\n"
        });
        return {status: response.status, alert: {type:'error', message:errors}, error:true};
      }
      return {status: response.status, alert: {type:'error', message:response.data.message}, error:true};
    }
    else if(response){
      if(response.data && response.data.alert) {
        return {status: response.status, alert: { type:response.data.info, message:response.data.message}, error:false, data:response.data};
      }
      return {status: response.status, message: response.message, error:false, data:response.data};
    }
    else if(!response){
      return {status: null, alert: {type:'error', message:"Ekki náðist að tengjast bakenda"}, error:true}
    }

    return
    
  }

  request (type, url, data) {
    let promise = null
    switch (type) {
      case 'GET': promise = axios.get(url, { params: data }); break
      case 'POST': promise = axios.post(url, data); break
      case 'PUT': promise = axios.put(url, data); break
      case 'DELETE': promise = axios.delete(url, data); break
      default : promise = axios.get(url, { params: data }); break
    }
    return promise
  }

}

export default HttpRequest