<template>
  <v-container pa-0 ma-0>
    <v-card flat>
      <v-col>
        <v-form ref="form" v-model="valid">
          <v-text-field v-model="registerModel.Email" :rules="emailRules" label="Póstfang" required prepend-inner-icon="mdi-email-outline" />
          <v-text-field v-model="registerModel.Password" :rules="passwordRules" label="Aðgangsorð" autocomplete="new-password" type="Password" prepend-inner-icon="mdi-lock-outline" required/>
          <v-text-field v-model="registerModel.ConfirmPassword" :rules="confirmPasswordRules" label="Aðgangsorð aftur" autocomplete="Confirm password" type="Password" prepend-inner-icon="mdi-lock-outline" required/>
          <v-btn
            @click="register"
            :disabled="!valid"
            :loading="loading"
            block
            class="primary"
            >
            {{ success ? '' : 'Skrásetja' }}
            <v-icon v-if="success" right dark>mdi-check</v-icon>
          </v-btn>
        </v-form>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data () {
    return {
      valid: false,
      email: '',
      registerModel:{
          Email:'',
          Password:'',
          ConfirmPassword:''

      },
      emailRules: [
        v => !!v || 'Póstfangs krafist', // 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'Póstfang verður að vera gilt póstfang (email)', // 'E-mail must be valid',
        v => v !== this.emailTaken || 'Þetta póstfang er þegar í notkun' // 'E-mail is already taken'
      ],
      passwordRules: [
          v => v.length > 7 || 'Aðgangsorð verður að vera minnst 8 stafir' // 'Passwords length is minimum 8'
      ],
      confirmPasswordRules: [
          v => v === this.registerModel.Password || 'Aðgangsorðin verða að vera eins' // 'Passwords must be the same'
      ],
      emailTaken: null,
      loading: false,
      success: false
    }
  },

  methods: {
    async register (e) {
      e.preventDefault()
      this.loading = true
       const result = await this.$store.dispatch('register', {Email: this.registerModel.Email, Password:this.registerModel.Password,ConfirmPassword:this.registerModel.ConfirmPassword} );
        //const result = await this.$userRegister({ Email: this.email })
        this.loading =false;
        if(result.error){
            //this.emailTaken = result.message;
            this.$refs.form.validate()
        }
        if (result) {
          this.success = true

          setTimeout(function () {
            this.$router.push({
              path: '/'
            })
          }.bind(this), 1000)
        }
      
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>

.v-card__title {
  background-color: #609FC7;
  color:#FFFFFF;
  font-size: 34px;
}

</style>
