export default class BreakPoints
{


  cols (breakpoint) {
    switch (breakpoint) {
      case 'sm': return 6
      case 'md': return 6
      case 'lg': return 4
      case 'xl': return 4
    }

    return 12
  }

  offset (breakpoint) {
    switch (this.cols(breakpoint)) {
      case 4: return 4
      case 6: return 3
    }

    return 0
  }
}
