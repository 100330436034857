<template>
<v-container ma-0 pa-0>
    <!-- <v-row v-if="moduleSelected === null">
      <v-col
        :cols="$bp.cols($vuetify.breakpoint.name)"
        :offset="$bp.offset($vuetify.breakpoint.name)"
      >

        <ModuleDescription
          v-for="module in modules"
          :key="module.id"
          :module="module"
          :cols="cols"
          :offset="offset"
        />
         <v-btn
         @click="addPr1oduct()"
      class="mx-2"
      fab
      dark
      color="indigo"
    >
      v-touch="{
      left: () => swipe(1),
      right: () => swipe(-1),
      up: () => swipe('Up'),
      down: () => swipe('Down')
    }"
    align="center"
    class="grey lighten-2"
    justify="center"
    style="height: 500px"
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>
      </v-col>
      -->
    <v-row v-if="devices.length == 0">
        <v-col class="ma-4 py-4" :cols="$bp.cols($vuetify.breakpoint.name)" :offset="$bp.offset($vuetify.breakpoint.name)">
            <h3>Pottur ekki skráður</h3>
            <p>Engin pottur skráður fyrir þennan reikning. Til að skrá pott þarf að para tæki við reikning. <router-link to="/info">Sjá fyrirmæli</router-link>
            </p>
        </v-col>
    </v-row>
    <v-row v-else-if="devices.length == 1">
        <v-col class="ma-0 py-0" :cols="$bp.cols($vuetify.breakpoint.name)" :offset="$bp.offset($vuetify.breakpoint.name)">

            <div style="margin-top: 25px">
                <v-row justify="center">
                    <v-col cols="12">
                        <div style="position:relative">
                            <div style="position: absolute; top: -20px; right: 1em; z-index: 3">
                                <v-btn @click="edit(devices[0].id)" class="ma-2" icon>
                                    <v-icon large>mdi-cog-outline</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <v-row justify-content="center" class="flex-nowrap">
                            <span class="center title">{{ title(devices[0].id) }}</span>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="center" style="margin-bottom:20px">
                    <div class=" description">{{ devices[0].description }}</div>
                </v-row>
                <Module v-if="devices[0].online && refreshModule" :id="selectedModule(devices[0].id)" />
                <div v-else>
                    <h3>Device is offline</h3>
                </div>
            </div>
        </v-col>
    </v-row>

    <v-row v-else>
        <v-col class="ma-0 py-0">
            <swiper ref="mySwiperRef" class="swiper">
                <swiper-slide v-for="device in devices" :key="device.id + 'key'">

                    <div style="margin-top: 25px;width:100%">
                        <v-row justify="center" style="width:100%">
                            <v-col cols="12">
                                <div style="position:relative;width:100%">
                                    <div style="position: absolute; top: -20px; right: 0em; z-index: 3">
                                        <v-btn @click="edit(device.id)" class="ma-2" icon>
                                            <v-icon large>mdi-cog-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </div>
                                <v-row justify-content="center" class="flex-nowrap">
                                    <span class="center title">{{ title(device.id) }}</span>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="8" xs="12" lg="9">
                                <div class=" description">{{ device.description }}</div>
                            </v-col>
                        </v-row>
                        <Module v-if="device.online && refreshModule" :id="selectedModule(device.id)" />
                        <v-row v-else justify="center">
                            <v-col cols="8" xs="12" lg="9">
                                <h3>Device is offline</h3>
                            </v-col>
                        </v-row>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev swiper-theme-color" slot="button-prev" @click="prev()"></div>
                <div class="swiper-button-next swiper-theme-color" slot="button-next" @click="next()"></div>
            </swiper>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import {
    Swiper,
    SwiperSlide
} from "vue-awesome-swiper";
// import style (>= Swiper 6.x)
import "swiper/swiper-bundle.css";
//import 'swiper/css/swiper.css'
// // import style (<= Swiper 5.x)
// import 'swiper/css/swiper.css'
import EventBus from '@/plugins/eventBus'
import Module from "@/components/Module.vue";
export default {
    middleware: "authenticated",

    components: {
        Module,
        Swiper,
        SwiperSlide,
    },

    data() {
        return {
            swiperOption: {
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction'
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            },
            isMounted: false,
            user: {
                email: "",
                password: "",
            },
            selTemp: 1,
            devices: [],
            modulesLocal: [],
            refreshModule: true
        };
    },

    computed: {
        ...mapGetters({
            modules: "modules",
            moduleSelected: "module",
        }),

        cols() {
            return this.$vuetify.breakpoint.mdAndDown ? 12 : 6;
        },

        offset() {
            return this.$vuetify.breakpoint.mdAndDown ? 0 : 3;
        },
    },

    async mounted() {
        await this.getDevices();
        this.devices = this.$store.getters.devices;

        EventBus.$on("force-update", () => {
            this.$nextTick().then(() => {
                // Add the component back in
                window.location.reload();
            })

        })
    },

    methods: {
        ...mapActions(["getDevices", "setModule"]),
        addProduct() {
            this.$refs.addDialog.openDialog();
            //this.$store.commit("SET_DIALOG",true)
            //this.$utils.dialog = true;
        },
        selectedModule(id) {
            return this.modules.find((m) => m.deviceId == id && m.moduleTypeId == 1).moduleId;
        },
        edit(deviceId) {
            this.$router.push({
                name: "editdevice",
                params: {
                    id: deviceId
                },
            });
        },
        title(id) {
            const idx = this.devices.findIndex((m) => m.id == id);
            if (idx != -1) {
                return this.devices[idx].name ?
                    this.devices[idx].name :
                    "Nafn ekki valið";
            }
            return "Ekkert tæki skráð";
        },
        swipe(direction) {
            this.setModule(direction);
        },
        swipeEnds(e) {
            console.log(e);
        },
        next() {
            this.$refs.mySwiperRef.$swiper.slideNext()
        },
        prev() {
            this.$refs.mySwiperRef.$swiper.slidePrev()
        },

    },
};
</script>

<style scoped>
/* .swiper {
  height: 500px;
  width: 100vh;
} */
.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
}

.title {
    font-size: 2em;
}

.description {
    font-size: 1em;
    font-weight: 300;
}

.intro-card {
    min-width: 330px;
}

.center-subtext {
    margin: auto;
    width: 70%;
    padding: 10px;
}

.center {
    margin: auto;
    padding: 0px;
}

.swiper-theme-color{
    color:rgba(0,0,0,0.2) !important
}

</style>
