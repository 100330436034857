<template>
  <v-container>
    <v-row>
      <v-col pa-2 ma-2>
        <v-card>
          <v-form ref="form" v-model="valid">
            <v-card-title><v-icon>mdi-lock-outline</v-icon>&nbsp;Týnt aðgangsorð</v-card-title>
            <v-card-text>
              Skráðu póstfangið fyrir aðganginn í reitinn 'Póstfang' hér fyrir neðan og ýttu svo á 'ENDURHEIMTA AÐGANG' hnappinn.<br>
              Þú færð sendan póst þangað með frekari leiðbeiningum til að endurheimta aðganginn.
            </v-card-text>
            <v-card-text>
              <v-text-field
                v-model="loginEmail"
                :rules="emailRules"
                counter="80"
                label="Póstfang"
                outline
                prepend-inner-icon="mdi-email-outline"
                />
            </v-card-text>
            <v-row>
              <v-col cols="6" class="px-5">
                <v-btn
                  @click="forgotPw"
                  depressed 
                  color="secondary"
                  :disabled="!valid"
                  :loading="loading">Endurheimta aðgang</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
   data() {
      return {
         email: "",
         loading: false,
         valid: false,
         emailRules: [
            (v) => !!v || "Sláðu inn gilt póstfang",
            (v) => /.+@.+\..+/.test(v) || "Póstfangið verður að vera gilt póstfang",
         ],
      };
   },
   methods: {
      async forgotPw(e) {
         e.preventDefault()
         try {
            this.loading = true
            await this.$store.dispatch('forgotPW', {Email: this.email} );
            this.loading = false ;
         }
         catch( ex ) {
            console.log(ex)
            this.loading = false ;
         }
      }
   },
};
</script>
