//import { getField, updateField } from 'vuex-map-fields'

//let hub = {}
const boardModule =
{
    state :{
        dialog:false
    },

    mutations :{
        //updateField,
        SET_DIALOG: (state, dialog) => {
            state.dialog = dialog;
        }
    },

    getters: {
        //getField,

        dialog:(state) => {
            return state.dialog;
        }
    },

    actions: {
        // clear ({ commit, state }) {
        //     if(state.modules != null){
        //         for (let i = 0; i < state.modules.length; i++) {
        //             hub.invoke('UnsubscribeToModule', state.modules[i].id)
        //         }
        //     }
            

        //     commit('modules', null)
        //     commit('module', null)
        // }
    }
}

export default boardModule
