import { HubConnectionBuilder } from '@aspnet/signalr'
import tokenManager from '@/services/tokenManager'
import store  from '@/store'
import eventbus from './eventBus'
import config from '@/appconfig.json'

export default class Hub{
  #connection={}
  constructor(){
    this.#connection = new HubConnectionBuilder()
    .withUrl(`${config.server_url}chatHub`, {
      accessTokenFactory () {
        return tokenManager.accessToken()
      } })
    .build()

    this.#connection.onclose(async () => {
      await this.start();
  });

    this.#connection.on('Connected', () => {
    })
  
    this.#connection.on('Disconnected', () => {
    })
  
    this.#connection.on('ReceiveData', () => {
    })
    this.#connection.on('Data', (module) => {
        store.dispatch('updateModuleData', module)
    })

    this.#connection.on('Response', (resp) => {
      const data = JSON.parse(resp);
      eventbus.$emit(data["command"],data["args"])

    })
  }

  command (id, data) {
    this.#connection.invoke('Command', id, JSON.stringify(data))
  }

  async start() {
    try {
        await this.#connection.start();
    } catch (err) {
        console.log(err);
        setTimeout(() => this.start(), 5000);
    }
  }

  async stop() {
    try{
      await this.#connection.stop();
    } catch (error) {
      console.log(error)
    }

  }

  on(event){
    return this.#connection.on(event, (data) =>{
      return data
    })
  }

  get Connection(){
    return this.#connection
  }

  invoke(method,message){
    this.#connection.invoke(method, message).catch(err => console.error(err));
  }
  

}
