export default class Utils{

    constructor(){
        this.dialog = false;
    }

    setDialog(payload){
        this.dialog = payload;
    }

    
}