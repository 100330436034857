import Vue from 'vue'
import App from './layouts/default'
import router from './router'
import store from './store'
import Axios from 'axios'
import vuetify from './plugins/vuetify';
//import hub from './services/signalr'
import Breakpoints from './plugins/breakpoints'
import Utils from '@/plugins/utils'
import signalr from '@/plugins/signalr'
Vue.prototype.$bp = new Breakpoints()
Vue.prototype.$utils = new Utils();


Vue.prototype.$http = Axios;
Vue.config.productionTip = false
Vue.prototype.$hub = signalr
//Vue.prototype.$hub =new hub();
// const token=localStorage.getItem('jwt')
// if( token ){
//   Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
// }
/* eslint-disable no-new */
new Vue({
  render: h => h(App),
  router,
  vuetify,
  store
}).$mount('#app')

