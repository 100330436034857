<template>
  <v-container ma-0 pa-0>
    
    <v-card flat>
      <!-- <v-img v-if="refsLoaded" :src="image" :height="imageHeight" contain class="white--text align-end" style="padding: 0px; margin: 0px; display: block">
            <v-card-title v-text="title" />
        </v-img> -->
      <!-- <v-card-title class="justify-center my-4" v-text="title" /> -->
      
      <v-card-text  class="pa-0" v-if="moduleData && moduleData.data" ref="controls">
        <v-layout pa-0 ma-0 v-if="gagueTemp">
          <v-row justify="center">
            <vue-svg-gauge
              v-if="resetGauge"
              :start-angle="-110"
              :end-angle="110"
              :separator-step="1"
              :separatorThickness="2"
              :gauge-color="gaugeColor"
              :scaleInterval="10"
              :inner-radius="80"
              :scale-step="5"
              :value="gagueTemp"
              :min="0"
              :max="50"
              class="mini-gauge"
              base-color="#d0cdcd"
            >
              <v-layout fill-height pa-0 ma-0 pt-8>
                <v-row dense style="line-height: initial">
                  <v-col>
                    <v-row justify="center">
                      <v-card
                        flat
                        color="rgb(255, 0, 0, 0.0)"
                        style="
                          margin: 0px;
                          padding: 0px;
                          overflow: hidden;
                          text-align: center;
                        "
                      >
                        <v-card-subtitle
                        class="temp-text"
                          style="
                            font-size: 1em;
                            margin: 0px;
                            padding: 0px;
                            display: inline-block;
                            font-weight: 600;
                          "
                          :style="{'color':textColor.tubTemp}"
                          @click="changeGaugeTemp(1)"
                        >
                          Hiti:
                          {{ moduleData.data.tub_temperature.toFixed(1) }}&deg;C
                        </v-card-subtitle>
                        <v-card-subtitle
                          class ="subText temp-text" ref="selectedTemp" :style="{'color':textColor.selTemp,'font-weight':600,'font-size':'1em'}" @click="changeGaugeTemp(2)"
                        >
                          Valið:
                          {{ moduleData.data.set_temperature.toFixed(1) }}&deg;C
                        </v-card-subtitle>
                        <v-card-subtitle
                          class ="subText temp-text" @click="changeGaugeTemp(3)" :style="{'color':textColor.flowTemp}"
                        >
                          Rennsli: {{ moduleData.data.inlet_temperature.toFixed(1) }}&deg;C
                        </v-card-subtitle>
                        <v-card-subtitle
                          class ="subText temp-text"
                          
                        >
                          <v-btn style="height: 3em;" icon>
                            <v-icon @click="turnOn()" :color="powerColor" large>
                              mdi-power-standby
                            </v-icon>
                          </v-btn>
                        </v-card-subtitle>
                      </v-card>
                    </v-row>
                  </v-col>
                </v-row>
              </v-layout>
            </vue-svg-gauge>
          </v-row>
        </v-layout>
      </v-card-text>
    </v-card>

    <v-layout>
      <v-row align-center>
        <v-col>
          <v-layout justify-space-around>
            <v-btn class="mx-2" fab dark color="indigo">
              <v-icon dark @click="increment_set_temperature($event, -1*tempChange)" large>
                mdi-minus
              </v-icon>
            </v-btn>

            <v-btn
              @click="increment_set_temperature($event, tempChange)"
              class="mx-2"
              fab
              dark
              color="red"
            >
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
    </v-layout>
  </v-container>

  <!-- <v-card-actions ref="actions">
        <v-btn class="ma-2" icon>
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-spacer />
        <v-btn icon>
          <v-icon @click="turnOn()" :color="powerColor" large>
            mdi-power-standby
          </v-icon>
        </v-btn>
      </v-card-actions> -->
  <!-- </v-card>
  </v-container> -->
</template>

<script>
import { VueSvgGauge } from "vue-svg-gauge";
import EventBus from '@/plugins/eventBus'

import { mapGetters } from 'vuex'
export default {
  props: ["id"],
  components: {
    "vue-svg-gauge": VueSvgGauge,
  },
  data() {
    return {
      set_temperature_timeout: null,
      set_temperature_local: null,
      setTemperature:0,
      refsLoaded: false,
      tempChange:0.5,
      tempActive:1,
      textColor:{
        tubTemp:"rgba(0, 0, 0, 0.9)",
        selTemp:"rgba(0, 0, 0, 0.6)",
        flowTemp:"rgba(0, 0, 0, 0.6)"
      },
      resetGauge:true,
      lockTemperature:false,
      moduleData:{},
      gaugeColor: [
        {
          offset: 50,
          color: "#156681",
        },
        {
          offset: 85,
          color: "#64bf8a",
        },
        {
          offset: 90,
          color: "#FF9D86",
        },
      ],
      gagueTemp:25
    };
  },

  computed: {
    ...mapGetters(["modules"]),

   

    //...mapFields({
      //image: 'module.image',
      // tub_temperature: 'module.data.tub_temperature',
      // set_temperature: 'module.data.set_temperature',
      // inlet_temperature: 'module.data.inlet_temperature',
      //id: 'module.id',
      //moduleId: 'module.moduleId',
      //active: 'module.data.active',
      //name: 'module.name',
      //overlay: "overlay",
    //}),

    display_set_temperature() {
      if (this.set_temperature_local !== null) {
        return this.set_temperature_local;
      }

      return this.moduleData.data.set_temperature;
    },


    color() {
      if (this.setTemperature < 10) {
        return "indigo";
      }
      if (this.setTemperature < 20) {
        return "teal";
      }
      if (this.setTemperature < 30) {
        return "green";
      }
      if (this.setTemperature < 40) {
        return "orange";
      }
      return "red";
    },

    powerColor() {
      return this.moduleData.data.active ? "green" : "red";
    },

    imageHeight() {
      if (this.$refs.controls) {
        const availableHeight =
          window.innerHeight -
          this.$refs.controls.clientHeight -
          this.$refs.actions.clientHeight -
          150;

        return availableHeight < window.innerHeight * 0.2 ? 0 : availableHeight;
      }
      return false;
    },
    
  },

  async mounted()  {
    const cModule = this.selectedModule();
    this.moduleData = JSON.parse(JSON.stringify(cModule));
     this.gagueTemp = this.selectedTemperature()
     console.log("controls",this.$refs.controls)
    //this.moduleData.data.active = this.moduleData.online;
    this.refsLoaded = true;
    EventBus.$on("set_temperature", (resp)=>
    {
      this.moduleData.data.set_temperature = resp;
      this.changeGaugeTemp(this.tempActive)
      // if(this.tempActive == 2){
      //   //this.$refs.selectedTemp.style.color = 'rgba(0, 0, 0, 0.9)'
      //    this.textColor.selTemp = 'rgba(0, 0, 0, 0.9)'
      // }
      // else{
      //   //this.$refs.selectedTemp.style.color = 'rgba(0, 0, 0, 0.6)'

      //   this.textColor.selTemp = 'rgba(0, 0, 0, 0.6)'
      // }
      
      
      this.lockTemperature = false;
      this.gagueTemp = this.selectedTemperature()
      this.$forceUpdate()
    })

    EventBus.$on("set_active", (resp)=> {
      this.moduleData.data.active = resp == "True" ? true : false;
    })

    EventBus.$on("new_data", () => {
      if(!this.lockTemperature){
        this.moduleData = JSON.parse(JSON.stringify(this.selectedModule()));
        this.gagueTemp = this.selectedTemperature()
      }
    })

    



  },

  methods: {
    changeGaugeTemp(val){
      this.tempActive = val;
      this.textColor.tubTemp = 'rgba(0, 0, 0, 0.6)'
      this.textColor.selTemp = 'rgba(0, 0, 0, 0.6)'
      this.textColor.flowTemp = 'rgba(0, 0, 0, 0.6)'

      if(val == 1) {
        this.textColor.tubTemp = 'rgba(0,0,0,0.9)';
        
      }
      if(val == 2 ) {
        this.textColor.selTemp='rgba(0,0,0,0.9)';
      }
      
      if(val == 3) {
        this.textColor.flowTemp='rgba(0,0,0,0.9)';
      }
      
    },
    turnOn() {
      const data = {
        command: "set_active",
        module_id: this.moduleData.moduleId,
        args: !this.moduleData.data.active ? "True" : "False",
      };


      this.$store.dispatch("sendCommand", {
        id: this.moduleData.id,
        data: data,
      });
    },
    

    sendNewTemperature(){
      const data = {
        command: "set_temperature",
        module_id: this.moduleData.moduleId,
        args: this.moduleData.data.set_temperature,
      };

      this.$store.dispatch("sendCommand", {
        id: this.moduleData.id,
        data: data,
      });
    },
    selectedModule(){
      const _module = this.$store.getters.modules.find(m=>m.moduleId == this.id)
      return _module
    },
    selectedTemperature(){
      if(!this.tempActive && this.moduleData && this.moduleData.data.tub_temperature){
        return this.moduleData.data.tub_temperature;
      }

      if(this.tempActive){
        if(this.tempActive == 1){
          return this.moduleData.data.tub_temperature;
        }
        else if(this.tempActive == 2){
          return this.moduleData.data.set_temperature;
        }
        else if(this.tempActive == 3){
          return this.moduleData.data.inlet_temperature;
        }
      }

      return ""
    },
    

    increment_set_temperature(event, increment) {
      event.preventDefault()
      // remove focus from button after click
      this.lockTemperature =true;
      event.target.blur();
      this.textColor.selTemp = 'rgba(100,100,100,0.5)'
      if (this.set_temperature_local === null) {
        this.set_temperature_local = this.moduleData.data.set_temperature;
      }
      this.set_temperature_local += increment;
      this.moduleData.data.set_temperature  = this.set_temperature_local;
      if(this.set_temperature_timeout){
          clearTimeout(this.set_temperature_timeout);
          this.set_temperature_timeout = null;
      }
      this.set_temperature_timeout = setTimeout(
        () => {
          this.moduleData.data.set_temperature  = this.set_temperature_local
          this.set_temperature_local = null;
          this.sendNewTemperature()
          
        },
        2000
      );

      
    },
  },
};
</script>

<style lang="scss">
.mini-gauge {
  max-width: 90%;
  .inner-text {
    width: 100%;
    height: 100%;
  }
}

.subText{
  font-size: 0.8em; 
  margin: 0px; 
  padding: 0px;
  line-height: 1.375em !important;
}

.v-btn::before {
  background-color: transparent !important;
}

.v-icon::before {
  background-color: transparent !important;
}

.v-card__subtitle, .v-card__text {
    line-height: 1.375em !important;
}

.temp-text{
  font-size: 1em;
  font-weight:600;
  cursor:pointer;
  /*color:rgba(0, 0, 0, 0.6);*/
}
</style>
