import tokenManager from '../../services/tokenManager'
import { AuthService } from './../../services'
import eventBus from '@/plugins/eventBus'
const accountModule = {
  //namespaced: true,
  state: {
    userProfile: {}
  },
  mutations: {
    SET_USERPROFILE (state, name) {
      state.name = name
    }
  },
  actions: {

    async login ({ commit }, data) {
      try{
        let result = await AuthService.loginUser(data)
        if(result.error && result.alert){
          eventBus.$emit("incomming-alert",result.alert)
          return;
        }

        commit('SET_USERPROFILE', result.data.email)
        return result
      }
      catch(ex){
        console.log(ex)
        eventBus.$emit("incomming-alert",ex)

      }
      //..set userdata
    },

    // eslint-disable-next-line no-unused-vars
    async register ( { commit },data) {
      let resp = await AuthService.registerUser(data);
      if(resp.error && resp.alert){
        eventBus.$emit("incomming-alert", resp.alert)
        return resp;
      }

      eventBus.$emit("incomming-alert", resp.alert)
      return resp;
    },
    async logout (){
      tokenManager.unsetToken();
      await AuthService.logout();
      this.dispatch("stopHubConnection")
      
      this.dispatch('clear');
    },
    // eslint-disable-next-line no-unused-vars
    async forgotPW({commit},payload) {
        const forgotResp = await AuthService.forgotPW(payload);
        if(forgotResp.alert){
          eventBus.$emit("incomming-alert", forgotResp.alert)
        }
        return forgotResp;
    },
    clear({ commit, state }){
      //find replacement
      if(state.modules != null){
        for (let i = 0; i < state.modules.length; i++) {
          this.$hub.invoke('UnsubscribeToModule', state.modules[i].id)
        }
      }
      
      commit('modules', null)
      commit('module', null)
      
    }
  },
  getters: {
    getuserProfile (state) {
      return state.userProfile
    }
  }
}

export default accountModule