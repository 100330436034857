<template>
    <v-container ma-0 pa-2>
        <div class="ma-0 pa-4"><h2>Snjallvæddir pottar frá Trefjum</h2></div>
        <v-row>
            <v-col  class="my-4 mx-0 py-0 px-4" :cols="12" sm="5">
                <v-card class="intro-card">
                    <v-card-title>Er búið að para pott við aðganginn?</v-card-title>
                    <v-card-text>Ef svo, má einfaldlega <router-link to="/userboard">sýsla með pottinn.</router-link></v-card-text>
                    <v-card-text>Einnig er hægt að smella á <v-icon>mdi-menu</v-icon> efst í vinstra horninu og velja þar "Heitur Pottur".</v-card-text>
                    <v-card-text>Ef pottur og aðgangur hafa ekki verið paraðir saman, er nauðsynlegt að lesa áfram og fylgja leiðbeiningum.</v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="my-4 mx-0 py-0" :cols="12" sm="5">
                <v-card class="intro-card">
                  <v-card-title><v-icon>mdi-thermometer-plus</v-icon>&nbsp;Para saman pott og aðgang</v-card-title>
                  <v-card-text>Athugið að til þess að bæta potti við þennan aðgang, verður að vera kveikt á pottstýringunni og hún nettengd.</v-card-text>
                  <v-card-text>Til þess að bæta við potti, þarf að smella á <v-icon>mdi-menu</v-icon> hér efst í vinstra horninu og velja "<router-link to="/new-device"><v-icon>mdi-thermometer-plus</v-icon>&nbsp;Bæta við potti</router-link>" liðinn úr þeirri valmynd.</v-card-text>
                  <!-- <v-card-actions><img src="@/assets/menu-bar.jpg" /></v-card-actions> -->
                  <v-card-text>Næst þarf að snúa sér að stýringunni sjálfri: Þar þarf að velja <v-icon>mdi-cog-outline</v-icon> á skjá stýringarinnar og svo "Para við reikning". Smellið á hnappinn 'BÚA TIL PÖUNARKÓÐA' í þeirri valmynd.<br>
                  Við það birtist birtist 6-stafa kóði í svæðið fyrir neðan hnappinn. Þennan kóða þarf að slá inn í svæðið "Tala á stýribúnaði" í "<router-link to="/new-device"><v-icon>mdi-thermometer-plus</v-icon>&nbsp;Bæta við potti</router-link>" valmyndinni hér á þessum aðgangi og ýta svo á hnappinn "PARA".</v-card-text>
                  <v-card-text>Við þetta parast saman pottstýringin og þessi aðgangur. Efir það er mögulegt að stýra pottinum héðan af þessum aðgangi.</v-card-text>
                  <v-card-text>Þegar ýtt hefur verið á "PARA" hnappinn, skal stutt aftur á <v-icon>mdi-menu</v-icon> hér á síðunni og velja þar "<router-link to="/userboard"><v-icon>mdi-hot-tub</v-icon>&nbsp;Heitur Pottur</router-link>".</v-card-text>
                  <v-card-text>Athugið að para má marga potta við aðganginn.</v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
}
</script>

<style scoped>
.intro-card {
  min-width:330px;
  max-width: 400px !important;
}
</style>
