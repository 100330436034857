<template>
    <div>
        <v-alert v-if="isAlert"
            border="right"
            colored-border
            :type="type"
            elevation="2"
        >
      <b>{{message}}</b>
    </v-alert>
    
    </div>
</template>

<script>
import eventBus from '@/plugins/eventBus'

export default {
    data() {
        return {
            message:"",
            type:"error",
            isAlert:false
        }
    },
    mounted(){
        eventBus.$on("incomming-alert",( data ) =>{
            this.message = data.message;
            this.type = data.type;
            this.isAlert =true
            setTimeout(() => {
                this.isAlert = false;
            }, 8000);
        })
    },
}
</script>