//import ModuleModel from './moduleModel'

export default class DeviceModel{
    constructor(device){
        this.id = device.id;
        this.name = device.name;
        this.userId = device.userId;
        this.description = device.description;
        this.modules = [];
        this.online = device.online;

        // if(device.modules != null || device.modules.length != 0){
        //     for(let i=0; i<device.modules.length; i++){
        //         this.modules.push(new ModuleModel(device.modules[i])); 
        //     }
        // }
        
         
    }
}