

export default class ModuleModel{
    constructor(module,deviceId){
        this.data = JSON.parse(module.data);
        this.id = module.id;
        this.moduleId = module.moduleId;
        this.moduleTypeId = module.moduleTypeId;
        this.name = null;
        this.deviceId = deviceId

    }
}