import Cookie from 'js-cookie'

function storeTokens(tokens){
    const access_token = {"token":tokens.accessToken.token , "expires":tokens.accessToken.expiredUTCMilliseconds}
    const refresh_token = {"token":tokens.refreshToken.token , "expires":tokens.refreshToken.expiredUTCMilliseconds}

    this.setToken(JSON.stringify(access_token), "access_token");
    this.setToken(JSON.stringify(refresh_token), "refresh_token");
}

function accessToken(){
    const accessToken = new TokenModel(Cookie.get("access_token"));
    if(!accessToken){
        return "";
    }
    return accessToken.token;
}

function refreshToken(){
    const refreshToken = new TokenModel(Cookie.get("refresh_token"));
    return refreshToken.token
}

function isValidToken(){
    const accessToken = new TokenModel(Cookie.get("access_token"));
    return accessToken.isValid;
}

function setToken(token, name){
    //if (process.server) { return }
    //const accessToken = new TokenModel(token);
    //const options ={ expires:accessToken.daysUntilExpires}//persistent ? { expires: 7 } : {}
    Cookie.set(name, token)
}

function unsetToken(){
    //if (process.server) { return }
    Cookie.remove('user')
    Cookie.remove('access_token')
    Cookie.remove('refresh_token')

}

function getToken(name){
    //if (process.server) { return null }
    return Cookie.get(name)
}

function isAuth() {
    const token = Cookie.get('access_token')
    if(token ==  null){
        return false;
    }else{
        return true;
    }
}


class TokenModel{
    #token=""
    constructor(tokenCookie){
        if(tokenCookie){
            this.#token = JSON.parse(tokenCookie)
        }
        else{
            this.#token = {token:"",expires:""}
        }
    }

    get isValid(){
        this.toDate;
        return true;
    }

    get toDate(){
        var d = new Date(0); 
        const milliseconds = Number(this.#token.expires)
        d.setUTCMilliseconds(milliseconds);
        return d;
    }

    get daysUntilExpires(){
        const today = new Date(Date.now());
        const milliseconds = this.toDate.getTime() - today.getTime()
        const days = milliseconds/(1000*60*60*24)
        return days;
    }

    get token(){
        return this.#token.token
    }
}

export default{
    accessToken,
    isValidToken,
    setToken,
    unsetToken,
    getToken,
    isAuth,
    refreshToken,
    storeTokens
} 