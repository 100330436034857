import HttpRequest from './http_request'

class DeviceProvider extends HttpRequest {

    async removeDevice(id){
      try
      {
        const delResponse = await this.delete("Devices",id);
        return this.responseHandler(delResponse);
      }
      catch(error){
        const result = this.responseHandler(error.response);
        return result;
      }

    }
}

export default DeviceProvider