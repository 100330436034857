import Vue from "vue";
import EventBus from "@/plugins/eventBus"

import { getField, updateField } from 'vuex-map-fields'
import http_request from '@/services/http_request'
const HttpRequest = new http_request();
import { DeviceService } from '@/services'

import Hub from '@/plugins/signalr';
let hub = {}
import DeviceModel from '@/models/deviceModel'
import ModuleModel from '@/models/moduleModel'

import { Plugins, Capacitor } from '@capacitor/core';
const { App } = Plugins;

const platform = Capacitor.getPlatform();
console.log("platform", platform)
if(platform == 'android'){
    App.addListener('appStateChange', (state) => {
        // state.isActive contains the active state
        EventBus.$emit("force-update")
        console.log('App state changed. Is active?', state.isActive);
    });
}



const boardModule=
{
    state :{
        modules: [],
        module: null,
        dialog: false,
        devices:[]
    },

    mutations :{
        updateField,

        modules: (state, payload) => {
            state.modules = payload
        },

        module: (state, payload) => {
            state.module = payload
        },

        updateModuleName: async (state, payload) => {
            //state.modules.
            state.modules[payload.id].name = payload.name;
            state.modules[payload.id].description = payload.description;
            return await this.HttpRequest.create(`Module/name/${state.module.id}/${name}`)
        },

        SET_MODULES:(state, modules) => {
            Vue.set(state,"modules",modules)
        },

        SET_DEVICES:(state, devices) => {
            Vue.set(state,"devices",devices)
        },
        CLEAR_DEVICES:(state) => {
            state.devices.length = 0;
        },
        UPDATE_DEVICE:(state,payload) => {
            // not in use
            const idx = state.devices.findIndex(m=>m.id == payload.id)
            if(idx != -1){
                state.devices[idx] = payload
            }
        },
        SET_SELECTED_MODULE:(state,id) => {
            const selectedModule = state.modules.find(m=>m.moduleId == id);
            Vue.set(state,'module',selectedModule)
        }
    },

    getters: {
        getField,
        
        modules: (state) => {
            return state.modules
        },

        module: (state) => {
            return state.module
        },

        devices: (state) => {
            return state.devices;
        }
    },

    actions: {
        // eslint-disable-next-line no-empty-pattern
        ADD_DEVICE_TO_ACCOUNT: async ( {}, secret ) => {
            
            let formData = new FormData();
            formData.append("secret",secret);
            let parsedResults = {};
            let addDeviceResponse = {};
            try{
                addDeviceResponse = await HttpRequest.create('/secret', formData)
                parsedResults = await HttpRequest.responseHandler(addDeviceResponse)
            }
            catch(ex){
                console.log(ex)
                parsedResults = await HttpRequest.responseHandler(ex)
                EventBus.$emit("incomming-alert",parsedResults.alert)
            }
            
            if(addDeviceResponse.status == 200){
                try{
                    await this.getDevices()
                }
                catch(ex){
                    console.log(ex)
                }
                
                this.$router.push("userboard")
                EventBus.$emit("incomming-alert",parsedResults.alert)
            }
            else {
                console.log("Could not add device")
                EventBus.$emit("incomming-alert",parsedResults.alert)
            }
        },

        GET_TEMPERATURE_CALIBRATION:  ( { state }, id )=> {
            const module = state.modules.find(m=>m.deviceId == id);
            console.log(state.modules)
            if(module == null){
                return null;
            }
            let temp_cal = [{value:module.data["temperature_calibration_1"]},{value: module.data["temperature_calibration_2"]}]
            return temp_cal
        },
        
        SET_DEVICE_PROPERTIES: async ( {state,dispatch},payload ) =>{
            const index = state.devices.findIndex(m=>m.id == payload.id)
            if(index != -1){
                let device=JSON.parse(JSON.stringify(state.devices[index]));
                if(payload.name){
                    device.name = payload.name;
                }
                if(payload.description){
                    device.description = payload.description;
                }
                device.modules.length = 0;
                await HttpRequest.update('/devices', device)
                //commit("UPDATE_DEVICE",device)
                await dispatch("getDevices");
            }
        },
        // eslint-disable-next-line no-unused-vars
        populateData: ( {commit} ) => {
            let devices= [];
            devices.push({})
            devices[0].id=1;
            devices[0].modules = [];
            devices[0].modules.push({})
            devices[0].modules[0]["data"] = JSON.stringify({
                "active":true,
                "inlet_temperature":32,
                "tub_temperature":21,
                "set_temperature":22,
                "drainValveState":"C",
                "inletValveState":"C",
                "draining":true
            })
            devices[0].modules[0]["id"] = 1
            devices.push({})
            devices[1]["modules"] = [];
            devices[1].modules.push({})
            devices[1].modules[0]["data"] = JSON.stringify({
                "active":true,
                "inlet_temperature":22,
                "tub_temperature":11,
                "set_temperature":12,
                "drainValveState":"C",
                "inletValveState":"C",
                "draining":true
            })
            devices[1].modules[0]["id"]= 2

            return devices;
        },
        
        getDevices: async ({ commit,dispatch }) => {
            //console.log(Router.push('userboard'))
            
            commit("CLEAR_DEVICES")
            let results = await HttpRequest.fetch('HubConnection')
            let devices = [];
            if(!results){
                return;
            }

            // eslint-disable-next-line no-constant-condition
            if (process.env.NODE_ENV === 'development' && false) {
                devices = await dispatch("populateData");
            }
            else {
                devices = results.data;
            }
            
            if(devices.length > 0){
                hub.length =0;
                hub = new Hub();
                await hub.start();
            }
            else{
                return;
            }
            let _devices = [];
            let _modules = [];
            for (let i = 0; i < devices.length; i++) {
                
                if(devices[i].modules == null || devices[i].modules.length == 0){
                    continue;
                }

                const deviceModel = new DeviceModel(devices[i]);
                _devices.push(deviceModel)
                for (let j = 0; j < devices[i].modules.length; j++) {
                    _modules.push(new ModuleModel(devices[i].modules[j],devices[i].id));
                    hub.invoke('SubscribeToModule', devices[i].modules[j].id)
                }
            }

            commit('SET_DEVICES', _devices)
            commit("SET_MODULES", _modules)

            const modules = []
            if (modules.length > 0) { commit('module', modules[0]) }

            return "finish"
        },

        // eslint-disable-next-line no-unused-vars
        sendCommand({commit},payload){
            hub.command(payload.id,payload.data)
        },

        moduleById({state},id){
            return state.modules.find(m=>m.moduleId == id)
        },
        stopHubConnection(){
            hub.stop();
        },
        setModule ({ commit,state }, direction) {
            if(direction === 1){
                let index = state.modules.findIndex(x => x.id === state.module.id)
                if(index >= state.modules.length-1){
                    commit('module', state.modules[index])
                }
                else{
                    index +=1;
                    commit('module',state.modules[index] )
                }
            }
            else if(direction === -1){
                let index = state.modules.findIndex(x => x.id === state.module.id)
                if(index == 0){
                    commit('module', state.modules[index])
                }
                else{
                    index -=1;
                    commit('module',state.modules[index] )
                }

            }
        },
        setModuleTemperature({commit,state},payload) {

            let modules = state.modules;
            let module = modules.find(m=>m.moduleId == payload.moduleId)
            module.data.set_temperature = payload.temperature;
            commit("SET_MODULES",modules)
        },

        async updateModuleImage ({ commit, state, dispatch }, image) {
            if (!image) { return }

            const fileURL = URL.createObjectURL(image)
            commit('updateModuleImage', fileURL)

            const formData = new FormData()

            formData.append('file', image)
            await this.$axios.post(`Module/image/${state.module.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            dispatch('getDevices')
        },

        updateModuleData: ({state,commit}, payload) => {
            let modules = state.modules;
            let module;
            // eslint-disable-next-line no-constant-condition
            if(process.env.NODE_ENV === 'development' && false){
               module = modules[0];
            }else
            {
               module = modules.find(m=>m.moduleId == payload.moduleId)
            }

            module.data = JSON.parse(payload.data);
            commit("SET_MODULES",modules)
            EventBus.$emit("new_data", true)
        },

        removeDevice: async ({dispatch}, payload) => {

            const resp = await DeviceService.removeDevice(payload)
            EventBus.$emit("incomming-alert", resp.alert)

            dispatch('getDevices')
            EventBus.$emit("device-deleted")
            
        }

    }
}

export default boardModule
