import Vue from 'vue'
import Vuex from 'vuex'
import PersonModule from './modules/person'
import AccountModule from './modules/account'
import BoardModule from './modules/board'
import DeviceModule from './modules/device'

// Make vue aware of Vuex
Vue.use(Vuex)

const modules = {
  Person: PersonModule,
  Account:AccountModule,
  Board: BoardModule,
  Device: DeviceModule
}

// Combine the initial state and the mutations to create a Vuex store.
// This store can be linked to our app.
export default new Vuex.Store({
  modules
})