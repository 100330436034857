<template>
<v-container>
     <v-card ma-2 class="paring-card ">
        <v-card-title>
            <span><v-icon>mdi-thermometer-plus</v-icon>&nbsp;Bæta við potti</span>
            <!-- <v-spacer></v-spacer>
            <v-menu
                bottom
                left
            >
                <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                </template>

            </v-menu> -->
        </v-card-title>
        <v-card-text>
            Til að para saman þennan aðgang og pottstýringuna, þarf að velja <v-icon>mdi-cog-outline</v-icon> á skjá pottsins, svo 'Para við reikning' og smella á 'BÚA TIL PÖRUNARKÓÐA' happinn.
            Við það birtist 6-stafa tala í svæðinu undir hnappnum sem þarf að slá inn í svæðið 'Tala á stýribúnaði' hér að neðan. Því næst þarf að ýta á 'PARA' hnappinn.
            Að því loknu má einfaldlega byrja að <router-link to="/userboard">sýsla með pottinn</router-link>.
        </v-card-text>

        <v-card-actions>
            <v-form @submit.prevent="submit">
                <!-- <validation-provider v-slot="{ errors }" name="Name" rules="required|max:10">
                    <v-text-field v-model="name" :counter="10" :error-messages="errors" label="Name" required></v-text-field>
                </validation-provider> -->
                <v-text-field v-model="secret" :counter="6" label="Tala á stýribúnaði" required></v-text-field>
                <div style="margin-top:20px">
                    <v-btn class="mr-4 primary" type="submit" :disabled="invalid">
                        Para
                    </v-btn>
                    <!-- v-btn @click="close()" class="accent">
                        Hætta við
                    </v-btn -->
                </div>
            </v-form>
        </v-card-actions>

    </v-card>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            invalid: false,
            errors: [],
            secret:""
        }
    },
    compute: {

    },
    watch: {
        secret(){
            if(this.secret.length < 6){
                this.invalid = true;
            }else{
                this.invalid = false;
            }
        }
    },
    methods: {
        close() {
            this.dialog = false;
        },
        openDialog() {
            this.dialog = true;
        },
        submit(){
            this.$store.dispatch("ADD_DEVICE_TO_ACCOUNT",this.secret)
        }

    }
}
</script>

<style>
.paring-card {
    max-width: 600px !important;
}
</style>
